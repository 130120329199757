import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import useSWR from 'swr';
import { setExtra, captureException } from '@sentry/browser';

import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { AxiosResponse } from 'axios';
import { get, post, put } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import {
  CandidateNotificationResponse,
  CandidateResponse,
} from 'lib/models/candidate';
import { httpHeadersState, userSecretState } from 'lib/atoms/userSecretAtom';
import {
  parseResponse,
  parseArrayResponse,
  parseErrorResponse,
} from 'lib/utils/parser';
import { InterestsResponse } from 'lib/models/interest';
import { StudentSocietyRegistration } from 'lib/models/student-society-registration';
import { UserResponse, UserRole } from 'lib/models/user';
import { ApplicationResponse } from 'lib/models/application';
import { Notice } from 'lib/models/notice';
import { OPPORTUNITY_TYPE } from 'lib/models/opportunity';
import { TouchpointType } from 'lib/models/touchpoint';
import { useAuth } from 'lib/providers/AuthProvider';
import { apiInstance } from 'lib/utils/axios';
import { TCandidate, TUserApiResponse } from 'lib/models/User.model';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useNotification } from './useNotification';

// Type for Onboarding education step
export type BasicInfo = {
  first_name: string;
  last_name: string;
  educationHistoriesId: string;
  university_id: string;
  university_name: string;
  degree_id: string;
  degree_label: string;
  subject_id: string;
  subject_name: string;
  degree_name: string;
  graduation_year: number;
  education_type: 'university';
};

export type DemographicsInfo = Partial<Omit<TCandidate, 'ethnicities'>> & {
  ethnicity_ids: string[];
};

export type RegisteredCandidateResponse = {
  already_registered: boolean;
};

export enum FLAG_STATUS_CODES {
  /** External opportunity apply/register popup */
  CANCEL = 0,
  SIGN_IN_FLOW = 1,
  APPLY_ANYWAY = 2,
  CLOSE = 4,
  /** Apply confirmation popup */
  CONFIRM_APPLY_YES = 5,
  CONFIRM_APPLY_INCORRECT_LINK = 6,
  /** Registration confirmation */
  NEW_USER_REGISTER_SUCCESS = 7,
  EXISTING_USER_REGISTER_SUCCESS = 8,
  REGISTER_FAILURE = 9,
  /** Join member modal */
  NAME_ADDED = 10,
  SUCCESS = 11,
}

export const useCandidate = () => {
  const router = useRouter();
  const notificationInstance = useNotification();
  const [userSecret] = useRecoilState(userSecretState);
  const { headers } = useRecoilValue(httpHeadersState);
  const {
    isCandidate: isUser,
    isTemporaryCandidate,
    updateUserApiResponse,
    setIsNewCandidate,
    candidate,
  } = useAuth();
  const { utm_source = 'producthunt' } = router.query;
  const { data: session, status } = useSession();

  const { data: candidateInterestsResponse, mutate: candidateInterestsMutate } =
    useSWR<InterestsResponse>(
      userSecret && isUser ? [API_ROUTES.CANDIDATE_INTERESTS, headers] : null,
      get,
      {
        revalidateOnFocus: false,
      }
    );

  const candidateResponsibilities = useMemo(() => {
    const list = parseArrayResponse(candidateInterestsResponse);
    return list
      .filter((item) => item.interestable_type === 'AreaOfResponsibility')
      .map((item) => ({
        interestable_id: item.interestable_id,
        interestable_type: 'AreaOfResponsibility',
        weight: item.weight,
      }));
  }, [candidateInterestsResponse]);

  const candidateIndustries = useMemo(() => {
    const list = parseArrayResponse(candidateInterestsResponse);
    return list
      .filter((item) => item.interestable_type === 'Industry')
      .map((item) => ({
        interestable_id: item.interestable_id,
        interestable_type: 'Industry',
        weight: item.weight,
      }));
  }, [candidateInterestsResponse]);

  const loginCandidate = async ({
    email,
    source,
    touchpointable_type,
    check_email,
  }: {
    email: string;
    source?: string;
    touchpointable_type?: TouchpointType;
    check_email?: boolean;
  }) => {
    const body = {
      user: {
        email,
        utm_source,
        userable_type: 'Candidate',
        userable_source: 'website',
      },
      touchpointable_type,
      source,
      check_email,
    };
    return await post<TUserApiResponse | RegisteredCandidateResponse>(
      API_ROUTES.CANDIDATE_CREATE,
      body
    );
  };

  const updateCandidateShowProfileModal = async ({
    show,
  }: {
    show: boolean;
  }) => {
    const body = {
      candidate: { show_complete_profile_banner: show },
    };
    try {
      const response = await apiInstance.put<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      updateUserApiResponse(response.data);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateEmailMatchesFrequency = async ({
    matchesFrequency,
  }: {
    matchesFrequency: string;
  }) => {
    const body = {
      candidate: { matches_email_frequency: matchesFrequency },
    };
    try {
      const response = await apiInstance.put<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      updateUserApiResponse(response.data);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };
  const updateShowEmailMatchesFrequency = async ({
    show,
  }: {
    show: boolean;
  }) => {
    const body = {
      candidate: { show_matches_frequency_banner: show },
    };
    try {
      const response = await apiInstance.put<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      updateUserApiResponse(response.data);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateChromeExtensionShowModal = async ({
    show,
  }: {
    show: boolean;
  }) => {
    const body = {
      candidate: { show_chrome_externsion_banner: show },
    };
    try {
      const response = await apiInstance.put<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      updateUserApiResponse(response.data);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateMatchingSwipeOverlay = async () => {
    const body = {
      candidate: { show_matching_swipe_overlay: false },
    };
    try {
      const response = await apiInstance.put<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      updateUserApiResponse(response.data);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateInterests = async ({
    selectedInterests,
    interestable_type,
  }: {
    selectedInterests: Array<{
      interestable_id: string;
      interestable_type: string;
      weight: number;
    }>;
    interestable_type: string;
  }) => {
    const body = {
      interests: selectedInterests,
      interestable_type_to_destroy: interestable_type,
    };
    try {
      const { data: response } = await apiInstance.post<InterestsResponse>(
        `${API_ROUTES.CANDIDATE_INTERESTS_UPDATE}?interestable_type_to_destroy=${interestable_type}`,
        body
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateResume = async ({
    name,
    file,
  }: {
    name: string;
    file: string;
  }) => {
    const body = {
      candidate: {
        resume: {
          name,
          file,
        },
      },
    };
    try {
      const response = await apiInstance.patch<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      updateUserApiResponse(response.data);

      return parseResponse(response.data);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const removeCandidateResume = async () => {
    const body = {
      candidate: {
        resume: { _destroy: true },
      },
    };
    try {
      const response = await apiInstance.patch<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      updateUserApiResponse(response.data);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const loginTemporaryCandidate = async () => {
    const body = {
      user: {
        email: '',
        is_temporary: true,
        userable_type: 'Candidate',
        userable_source: 'website',
      },
    };
    try {
      return await post<TUserApiResponse>(API_ROUTES.CANDIDATE_CREATE, body);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const authenticateUser = async (candidateId: string, token: string) => {
    const route = `${API_ROUTES.CANDIDATE}?candidate_id=${candidateId}&magic_token=${token}`;
    const response = await get<TUserApiResponse>(route);
    updateUserApiResponse(response);
  };

  const updateCandidateEmail = async ({ email }: { email: string }) => {
    const body = {
      user: { email, is_temporary: false },
    };
    try {
      const { data } = await apiInstance.put<TUserApiResponse>(
        API_ROUTES.CANDIDATE_EMAIL_UPDATE,
        body
      );
      if (data) {
        return data;
      } else {
        throw Error('Something went wrong');
      }
    } catch (error) {
      if (isTemporaryCandidate) {
        throw error;
      } else {
        console.error(error);
        notificationInstance.handleExceptionError(error);
      }
    }
  };

  const updateCandidatePhoto = async ({
    headers,
    file,
  }: {
    headers: Headers;
    file: string;
  }) => {
    const body = {
      candidate: {
        avatar: { file },
      },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const getUserNotification = async () => {
    try {
      const { data: response } =
        await apiInstance.get<CandidateNotificationResponse>(
          `${API_ROUTES.CANDIDATE_NOTIFICATIONS}?page=1&per_page=1000`
        );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const readAllCandidateNotifications = async () => {
    const body = {};
    try {
      const { data: response } = await apiInstance.patch(
        `${API_ROUTES.CANDIDATE_NOTIFICATIONS}/read_all`,
        body
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const toggleCandidateNotification = async (enabled: boolean) => {
    const body = {
      candidate: {
        notifications_enabled: enabled,
      },
    };
    try {
      const { data: response } = await apiInstance.put<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const deleteCandidate = async (callback?: () => void) => {
    try {
      await apiInstance.delete(API_ROUTES.CANDIDATE);
      callback && callback();
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onSubscribe = async ({
    email,
    student_society_id,
  }: {
    email: string;
    student_society_id: string;
  }) => {
    const body = {
      student_society_registration: {
        email,
        student_society_id,
      },
    };
    try {
      const response = await post<{ data: StudentSocietyRegistration }>(
        API_ROUTES.CANDIDATE_SIGNUP,
        body,
        headers
      );
      if (response) {
        return parseResponse(response);
      }
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const isNewCandidate = (
    response: TUserApiResponse | RegisteredCandidateResponse
  ) => {
    const isNew = !(
      'already_registered' in response && response.already_registered
    );
    setIsNewCandidate(isNew);
    return isNew;
  };

  const verifyCandidateOTP = async ({
    email,
    otp,
  }: {
    email: string;
    otp: string;
  }) => {
    const body = {
      user: {
        email,
        otp,
      },
    };
    try {
      return await post<TUserApiResponse>(API_ROUTES.SIGNIN, body);
    } catch (error) {
      const message = parseErrorResponse(error);
      throw Error(message);
    }
  };

  const isCandidate = (
    response: CandidateResponse | UserResponse
  ): response is CandidateResponse => {
    return response.data.type.toLowerCase() === UserRole.CANDIDATE;
  };

  const updateTouchpointApplicationRating = async (
    headers: Headers,
    touchpoint_application_id: string,
    rating: number
  ) => {
    const body = {
      touchpoint_application: {
        rating,
      },
    };
    const path = API_ROUTES.APPLICATION_RATING.replace(
      '[id]',
      touchpoint_application_id
    );
    try {
      return await put<ApplicationResponse>(path, body, headers);
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const {
    onboarding_completed,
    next_job_state_empty,
    next_internship_state_empty,
    next_event_state_empty,
    opportunity_types,
  } = candidate || {};

  const isNonOnboardedUser = useMemo(() => {
    if (isUser && onboarding_completed === false) {
      return true;
    } else {
      return false;
    }
  }, [isUser, onboarding_completed]);

  const isOldOnboardedUser = useMemo(() => {
    if (
      isUser &&
      candidateInterestsResponse &&
      onboarding_completed === false
    ) {
      const filterInterest = candidateResponsibilities.filter(
        (data) => data.interestable_type === 'AreaOfResponsibility'
      );
      if (filterInterest && filterInterest.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }, [isCandidate, candidateResponsibilities]);

  const isNextMatchesAvailable = useMemo(() => {
    if (!opportunity_types || opportunity_types.length === 0) return false;

    const nextJobMatchesAvailable =
      opportunity_types.includes(OPPORTUNITY_TYPE.JOB) && !next_job_state_empty;
    const nextInternshipMatchesAvailable =
      opportunity_types.includes(OPPORTUNITY_TYPE.INTERNSHIP) &&
      !next_internship_state_empty;
    const nextEventMatchesAvailable =
      opportunity_types.includes(OPPORTUNITY_TYPE.EVENT) &&
      !next_event_state_empty;

    return (
      nextJobMatchesAvailable ||
      nextInternshipMatchesAvailable ||
      nextEventMatchesAvailable
    );
  }, [
    next_job_state_empty,
    next_internship_state_empty,
    next_event_state_empty,
    opportunity_types,
  ]);

  const onUnsubscribe = async (headers: Headers, id: string) => {
    const body = {
      student_society_id: id,
    };
    try {
      return await post<Notice>(
        `${API_ROUTES.STUDENT_SOCIETY_REGISTRATION}/unsubscribe`,
        body,
        headers
      );
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onGoogleLoginAuthentication = async () => {
    if (session && status === 'authenticated') {
      const { user, oauth_uid, id_token, access_token, image } = session || {};
      const { name, email } = user || {};
      const first_name = name ? name.split(' ')[0] : '';
      const last_name = name ? name.split(' ')[1] : '';

      const body = {
        email,
        oauth_provider: 'google',
        oauth_uid,
        id_token,
        access_token,
        first_name: first_name || '',
        last_name: last_name || '',
        utm_source,
        avatar: image,
      };

      try {
        const response = await post<TUserApiResponse>(
          '/api/v1/oauth_google',
          body
        );
        setExtra('response', JSON.stringify(response));
        setExtra('email', JSON.stringify(email));
        updateUserApiResponse(response);
        return response;
      } catch (error) {
        console.error(error);
        captureException(error);
        notificationInstance.handleExceptionError(error);
      }
    }
  };

  const onAppleLoginAuthentication = async () => {
    if (session && status === 'authenticated') {
      const { user, oauth_uid, id_token, access_token, image } = session || {};

      const { name, email } = user || {};
      const first_name = name ? name.split(' ')[0] : email;
      const last_name = name ? name.split(' ')[1] : email;

      const body = {
        email,
        oauth_provider: 'apple',
        oauth_uid,
        id_token,
        access_token,
        first_name: first_name || '',
        last_name: last_name || '',
        utm_source,
        avatar: image,
      };

      try {
        const response = await post<TUserApiResponse>(
          '/api/v1/oauth_apple',
          body
        );
        updateUserApiResponse(response);
        return response;
      } catch (error) {
        console.error(error);
        notificationInstance.handleExceptionError(error);
      }
    }
  };

  const updateLanguageBanner = async ({
    headers,
    show_add_language_banner,
  }: {
    headers: Headers;
    show_add_language_banner: boolean;
  }) => {
    const body = {
      candidate: { show_add_language_banner },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCVBuilderBanner = async ({
    show_cv_builder_banner,
  }: {
    show_cv_builder_banner: boolean;
  }) => {
    const body = {
      candidate: { show_cv_builder_banner },
    };
    try {
      const { data } = await apiInstance.patch<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      updateUserApiResponse(data);
      return parseResponse(data);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateInfo = async ({
    partialCandidateAttributes,
  }: {
    partialCandidateAttributes: Partial<Omit<TCandidate, 'ethnicities'>> & {
      ethnicitity_ids?: string[];
      city_ids?: string[];
    };
  }) => {
    const body = {
      candidate: { ...partialCandidateAttributes },
    };
    try {
      const { data: response } = await apiInstance.put<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      updateUserApiResponse(response);
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateBasicInfo = async ({ data }: { data: BasicInfo }) => {
    const {
      educationHistoriesId,
      education_type,
      university_id,
      university_name,
      subject_id,
      subject_name: _,
      degree_id,
      degree_name,
      ...rest
    } = data;

    const body = {
      candidate: {
        ...rest,
        education_histories_attributes:
          subject_id ||
          degree_id ||
          degree_name ||
          university_id ||
          university_name
            ? [
                {
                  id: educationHistoriesId || '',
                  education_type,
                  university_id: university_id || '',
                  university_name: !university_id ? university_name : '',
                  subject_id,
                  degree_id,
                  degree_name,
                  /** Adding this field as candidate might not
                   * enter all the mandatory values in Onboarding
                   */
                  skip_validation: true,
                },
              ]
            : undefined,
      },
    };

    try {
      const { data: response } = await apiInstance.put<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      updateUserApiResponse(response);
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const deleteCV = async () => {
    if (!candidate?.resume_url) return;

    const body = {};
    try {
      const { data: response } = await apiInstance.post<TUserApiResponse>(
        `${API_ROUTES.CANDIDATE}/remove_cv_parsed_details`,
        body
      );
      updateUserApiResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const hideAffiliatedSocietiesSection = async ({
    headers,
    show_affiliated_societies,
  }: {
    headers: Headers;
    show_affiliated_societies: boolean;
  }) => {
    const body = {
      candidate: { show_affiliated_societies },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const markCoverLetterPopUpSeen = async () => {
    const body = {
      candidate: {
        show_cv_builder_popup: false,
      },
    };
    try {
      const response = await apiInstance.put<AxiosResponse<CandidateResponse>>(
        API_ROUTES.CANDIDATE,
        body
      );
      return parseResponse(response.data.data);
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCount = async (payload: { [key: string]: number }) => {
    const body = {
      candidate: payload,
    };

    try {
      const response = await apiInstance.put<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      if (response) updateUserApiResponse(response.data);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const redirectToCoverLetterBuilder = (opportunityId?: string) => {
    router.push(
      {
        pathname: PAGE_ROUTES.CANDIDATE_BUILD_COVER_LETTER,
        query: { opportunityId },
      },
      PAGE_ROUTES.CANDIDATE_BUILD_COVER_LETTER
    );
  };

  const upgradeToProTrialPlan = useCallback(
    async ({ source }: { source?: string } = {}) => {
      const route = source
        ? `${API_ROUTES.TRIAL_PRO_PLAN}?source=${source}`
        : API_ROUTES.TRIAL_PRO_PLAN;
      const response = await apiInstance.post<TUserApiResponse>(route);
      updateUserApiResponse(response.data);
    },
    []
  );

  return {
    loginCandidate,
    updateCandidateInterests,
    updateCandidateResume,
    removeCandidateResume,
    loginTemporaryCandidate,
    updateCandidateEmail,
    authenticateUser,
    updateCandidatePhoto,
    getUserNotification,
    readAllCandidateNotifications,
    toggleCandidateNotification,
    deleteCandidate,
    onSubscribe,
    verifyCandidateOTP,
    isCandidate,
    updateTouchpointApplicationRating,
    isNonOnboardedUser,
    isOldOnboardedUser,
    candidateInterestsResponse,
    candidateResponsibilities,
    candidateIndustries,
    candidateInterestsMutate,
    isNextMatchesAvailable,
    onUnsubscribe,
    onGoogleLoginAuthentication,
    onAppleLoginAuthentication,
    isNewCandidate,
    updateLanguageBanner,
    updateCVBuilderBanner,
    hideAffiliatedSocietiesSection,
    updateCandidateShowProfileModal,
    updateChromeExtensionShowModal,
    updateMatchingSwipeOverlay,
    markCoverLetterPopUpSeen,
    updateCandidateInfo,
    redirectToCoverLetterBuilder,
    updateShowEmailMatchesFrequency,
    updateCandidateEmailMatchesFrequency,
    updateCount,
    updateCandidateBasicInfo,
    deleteCV,
    upgradeToProTrialPlan,
  };
};
