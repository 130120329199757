import React, { memo, useState } from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';

import Icon from 'components/Icon';
import Image from 'components/Image';

import styles from './Avatar.module.scss';

export type AvatarSize =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | '36'
  | '40'
  | '80'
  | '100'
  | 'xxlarge'
  | 'xxxlarge';

export interface AvatarProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** name of the user */
  name: string;
  /** icon url of the user */
  icon?: string;
  /** alt text for image*/
  altText?: string;
  isHuzzleVerified?: boolean;
  isVerified?: boolean;
  /** size */
  size: AvatarSize;
}

export const Avatar: React.FC<AvatarProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    name,
    icon,
    altText = 'image',
    size = 'medium',
    isHuzzleVerified = false,
    isVerified = false,
  }: AvatarProps) => {
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
      setImageError(true);
    };
    const contentClassNames = cn(
      {
        [styles.Avatar]: true,
        [`${styles[`AvatarSize${capitalize(size)}`]}`]: size,
      },
      className
    );

    const initials = name
      ?.split(' ')
      .map((word) => word[0])
      .join('')?.[0];
    return (
      <div style={style} className={contentClassNames} data-test="Avatar">
        {imageError || !icon ? (
          <>
            {initials ? (
              initials
            ) : (
              <Image
                src="default-avatar.png"
                source="aws"
                style={style}
                className={contentClassNames}
                data-test="AvatarImage"
              />
            )}
          </>
        ) : (
          <Image
            src={icon}
            source={icon ? undefined : 'aws'}
            style={style}
            className={contentClassNames}
            data-test="AvatarImage"
            onError={handleImageError}
            alt={altText}
          />
        )}
        {isHuzzleVerified && (
          <Icon iconName="huzzle-verified" className={styles.AvatarBadge} />
        )}
        {!isHuzzleVerified && isVerified && (
          <Icon
            iconName="tick-mark-circle"
            className={cn(styles.AvatarBadge, styles.verifiedIcon)}
          />
        )}
      </div>
    );
  }
);

Avatar.displayName = 'Avatar';
